import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Context} from '../AppContext';
import Billing from '../Models/Billing';
import {billingUtil, date} from '../Utils';
import {message} from 'antd';
import {ErrBilling, errorHandler} from '../errors';

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function useBillingList({noPage = false, query = {}}) {
  const app = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [filters, _setFilters] = useState({
    ...PAGINATION_INIT,
  });
  const [total, setTotal] = useState(0);

  const getRecords = useCallback(
    async (search = '') => {
      setLoading(true);
      try {
        const params = {
          query: {
            // name: {$regex: search},
            ...query,
          },
          paging: {
            offset: (filters.current - 1) * filters.pageSize,
            limit: filters.pageSize,
          },
          sorting: ['-created'],
        };

        if (noPage) {
          delete params.paging;
          delete params.sorting;
        }
        let resp = await app.actions.getBillings(params);
        if (noPage) {
          setRecords(resp);
          setTotal(resp.length);
        } else {
          setRecords(resp.results);
          setTotal(resp.total);
        }
      } catch (ex) {
        console.warn(ex);
      }
      setLoading(false);
    },
    [app.actions, filters],
  );

  function setFilters(obj) {
    _setFilters((prev) => ({
      ...prev,
      ...PAGINATION_INIT,
      ...obj,
    }));
  }

  const create = useCallback(async ({quotation, customer}) => {
    const action = '新增';

    let newBillings = [];

    async function initialBillings() {
      message.loading('建立客戶帳務資料');
      newBillings.push(
        new Billing({
          quotation: quotation.id,
          quotation_display_id: quotation.display_id,
          quotation_name: quotation.name,
          customer: customer.id,
          customer_name: customer.name,
          pay_period: customer.pay_period,
          price: quotation.total,
          deadline: date.parseToTimestamp(
            billingUtil.getDeadline(quotation.deal_date, customer.pay_period),
          ),
        }),
      );

      message.loading('建立廠商帳務資料');
      let {
        dispatch_records = [],
        dispatches = [],
        suppliers = [],
      } = await app.actions.getBillingInfo(quotation.id);
      for (let d of dispatches) {
        for (let s of suppliers) {
          let d_records = dispatch_records.filter(
            (r) => r.dispatch === d.id && r.supplier === s.id,
          );
          if (d_records.length > 0) {
            let price = d_records.reduce((sum, r) => sum + r.price, 0);

            newBillings.push(
              new Billing({
                quotation: quotation.id,
                quotation_display_id: quotation.display_id,
                quotation_name: quotation.name,
                supplier: s.id,
                supplier_name: s.name,
                dispatch: d.id,
                dispatch_display_id: d.display_id,
                pay_period: s.pay_period,
                price,
                deadline: date.parseToTimestamp(
                  billingUtil.getDeadline(quotation.deal_date, s.pay_period),
                ),
              }),
            );
          }
        }
      }
      newBillings = newBillings.map(({id, ...values}) => values); // remove id
    }

    app.actions.setLoading(true);

    try {
      await initialBillings();

      if (newBillings.length > 0) {
        message.loading('儲存帳務資料中');
        await app.actions.addBillings(newBillings);

        let msg = [
          ...new Set(
            newBillings
              .map((b) => b.supplier_name)
              .concat(newBillings.map((b) => b.customer_name)),
          ),
        ]
          .filter((x) => !!x)
          .join(',');
        message.success(`新增 ${msg} 帳務成功`);
      }
    } catch (err) {
      if (err.detail && err.detail[0] === 'not_supervisor') {
        message.error('您不是管理員身份，請開通後再試。');
      }
      errorHandler(err, action + '錯誤');
      throw err;
    }

    app.actions.setLoading(false);
  }, []);

  const edit = async (record) => {
    const action = '編輯';
    app.actions.setLoading(true);
    try {
      const params = {
        query: {id: record.id},
        data: record,
      };
      await app.actions.editBilling(params);

      message.success(action + '成功');
    } catch (ex) {
      errorHandler(ex, action + '錯誤');
    }
    app.actions.setLoading(false);
  };

  const toVoid = async () => {
    const action = '取消帳務';
    if (records.length > 0) {
      app.actions.setLoading(true);

      const _billings = records.map((x) => ({
        id: x.id,
        void: true,
      }));

      try {
        await app.actions.editBillings(_billings);

        message.success(action + '成功');
      } catch (ex) {
        errorHandler(ex, action + '錯誤');
      }
      app.actions.setLoading(false);
    }
  };

  useEffect(() => {
    getRecords();

    return () => setRecords([]);
  }, [getRecords]);

  return {
    records,
    total,
    onUpdate: getRecords,
    filters,
    setFilters,
    loading,
    create,
    edit,
    toVoid,
  };
}
