import React from 'react';
import styled from 'styled-components';

export default function Block(props) {
  const {
    title = '',
    children,
    actions = [],
    style = {},
    titleStyle = {},
    contentStyle = {},
  } = props;
  return (
    <div style={style}>
      <Title style={titleStyle}>
        <div style={{flex: 1}}>{title}</div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {actions.map((act, idx) => (
            <Action key={idx} onClick={act.onClick} title={act.title}>
              {act.icon}
            </Action>
          ))}
        </div>
      </Title>
      <Content style={contentStyle}>{children}</Content>
    </div>
  );
}

const Content = styled.div`
  padding: 20px;
  border: solid 0.5px #ddd;
`;

const Title = styled.div`
  border: solid 0.5px #ddd;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border-bottom: solid 3px #ddd;
  color: #505050;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const Action = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
  }
`;
