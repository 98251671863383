import React, {Fragment, useContext} from 'react';
import {Card, Alert, Spin} from 'antd';
import {date} from '../../Utils';
import {AlertOutlined} from '@ant-design/icons';
import {NotificationsOff, Edit, Check} from 'styled-icons/material';
import {Context} from '../../AppContext';
import BillingForm from '../../Forms/BillingForm';

import useBillingList from '../../hooks/use-billing-list';
const config = require('../../data.json');

export default function RemindList(props) {
  const app = useContext(Context);

  let deadline = new Date();
  deadline.setHours(0, 0, 0, 0);
  deadline.setDate(26);

  const {records, loading, onUpdate, edit} = useBillingList({
    noPage: true,
    query: {
      complete: false,
      deadline: {
        $lt: deadline.getTime(),
      },
      void: false,
    },
  });

  if (loading && records.length === 0) {
    return <Spin spinning={true} />;
  }

  if (records.length <= 0) {
    return (
      <Alert
        type="success"
        showIcon
        message="目前沒有即將到期的帳務"
        description="沒有本月25號到期的帳務"
      />
    );
  }

  return (
    <Fragment>
      <Alert
        type="warning"
        showIcon
        message="有即將到期的帳務"
        description="請優先處理"
        style={{marginBottom: 30}}
      />
      <Spin spinning={loading}>
        <div style={{display: 'flex', width: '100%', overflow: 'scroll'}}>
          {records.map((x) => (
            <Card
              key={x.id}
              title={
                <div style={{color: config.colors.error}}>
                  <AlertOutlined style={{fontSize: 18, marginRight: 10}} />
                  {date.format(x.deadline, 'YYYY-MM-DD')}
                </div>
              }
              style={{flex: '0 0 250px', borderRadius: 4, marginRight: 20}}
              actions={[
                // <NotificationsOff
                //   color="#999"
                //   size="18"
                //   key="read"
                //   onClick={async () => {
                //     if (window.confirm('確定不再提醒？')) {
                //       await edit({...x, read: true});
                //       onUpdate();
                //     }
                //   }}
                // />,
                <Edit
                  key="edit"
                  color="#999"
                  size="18"
                  onClick={() => {
                    app.actions.setModal(
                      <BillingForm record={x} onUpdate={onUpdate} />,
                    );
                  }}
                />,
                <Check
                  key="complete"
                  size="18"
                  color={config.colors.error}
                  onClick={async () => {
                    if (window.confirm('確定已完成？')) {
                      await edit({...x, complete: true});
                      onUpdate();
                    }
                  }}
                />,
              ]}>
              {x.customer && (
                <Fragment>
                  <p>客戶名稱：{x.customer_name}</p>
                  <p>報價名稱：{x.quotation_name}</p>
                </Fragment>
              )}
              {x.supplier && (
                <Fragment>
                  <p>廠商名稱：{x.supplier_name}</p>
                  <p>工單單號：{x.dispatch_display_id}</p>
                </Fragment>
              )}
              <p>金額：$ {x.price}</p>
              <p>備註：{x.note}</p>
            </Card>
          ))}
        </div>
      </Spin>
    </Fragment>
  );
}
