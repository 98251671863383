export default class Billing {
  constructor(record = {}) {
    this.id = null;
    this.customer = null;
    this.customer_name = '';
    this.supplier = null;
    this.supplier_name = '';
    this.dispatch = null; // 有supplier
    this.dispatch_display_id = '';
    this.quotation = null; // 有custmer
    this.quotation_display_id = '';
    this.quotation_name = '';
    this.pay_period = 0;
    this.deadline = parseInt(new Date().getTime());
    this.price = 0;
    this.note = '';
    this.complete = false;
    this.void = false;

    Object.assign(this, record);
  }
}

Billing.labels = {
  id: '系統編號',
  customer: '客戶編號',
  customer_name: '客戶名稱',
  supplier: '廠商編號',
  supplier_name: '廠商名稱',
  dispatch: '工單編號',
  dispatch_display_id: '工單單號',
  quotation: '報價編號',
  quotation_display_id: '報價單號',
  quotation_name: '報價名稱',
  pay_period: '付款票期',
  deadline: '截止日期',
  price: '金額',
  note: '備註',
  complete: '完成',
  void: '取消',
};
