import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Table, Button, Modal, Input} from 'antd';
import {Context} from '../../AppContext';
import Billing from '../../Models/Billing';
import {Link} from 'gatsby';
import useBillingList from '../../hooks/use-billing-list';
import {date} from '../../Utils';
import BillingForm from '../../Forms/BillingForm';

const labels = Billing.labels;

export default function BillingsOfCustomer(props) {
  const {customer} = props;
  const [search, setSearch] = useState('');
  const {
    records,
    total,
    filters,
    onUpdate,
    setFilters,
    loading,
  } = useBillingList({query: {customer: customer ? customer.id : {$ne: null}}});
  const app = useContext(Context);

  const columns = useRef([
    {
      title: labels.deadline,
      render: (record) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            app.actions.setModal(
              <BillingForm record={record} onUpdate={onUpdate} />,
            );
          }}>
          {date.format(record.deadline, 'YYYY-MM-DD')}
        </a>
      ),
      // (
      //   <Link to={`/billing/?id=${record.id}`}>
      //     {date.format(record.deadline, "YYYY-MM-DD")}
      //   </Link>
      // ),
    },
    {
      title: labels.customer_name,
      dataIndex: 'customer_name',
      key: 'customer_name',
    },
    {
      title: labels.quotation_name,
      dataIndex: 'quotation_name',
      key: 'quotation_name',
    },
    {title: labels.price, dataIndex: 'price', key: 'price'},
    {title: labels.note, dataIndex: 'note', key: 'note'},
    {
      title: labels.complete,
      key: 'complete',
      render: (record) => (record.complete ? '✔' : '✘'),
    },
    {
      title: labels.void,
      key: 'void',
      render: (record) => (record.void ? '✔' : '✘'),
    },
  ]).current;

  return (
    <Wrapper>
      <div className="action-bar">
        <Button type="primary" onClick={() => onUpdate(search)}>
          刷新
        </Button>
        {/* <Input
          value={search}
          placeholder="搜尋姓名"
          style={{width: 250}}
          onChange={(e) => setSearch(e.target.value)}
        />
        
        <div style={{flex: 1}} /> */}
        {/* <Button type="primary">匯出廠商清單</Button> */}
      </div>
      <Table
        loading={loading}
        columns={columns}
        rowKey="id"
        dataSource={records}
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onChange={(pagination) =>
          setFilters({
            pageSize: pagination.pageSize,
            current: pagination.current,
          })
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .action-bar {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    background-color: #fafafa;

    & > button {
      margin-left: 10px;
    }
  }
`;
