import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
import Block from '../../Components/Block';
import RemindList from './RemindList';
import CustomerBilling from '../../Components/BillingTable/CustomerBilling';
import SupplierBilling from '../../Components/BillingTable/SupplierBilling';

export default function BillingPage(props) {
  return (
    <Wrapper>
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <RemindList />
        </Col>
        <Col span={24}>
          <Block title="客戶帳務列表" contentStyle={{padding: 0}}>
            <CustomerBilling />
          </Block>
        </Col>
        <Col span={24}>
          <Block title="廠商帳務列表" contentStyle={{padding: 0}}>
            <SupplierBilling />
          </Block>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > button {
      margin-left: 10px;
    }
  }
`;
