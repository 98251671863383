import React, {useContext, Fragment} from 'react';
import {Context} from '../AppContext';
import useForm from '../hooks/use-form';
import styled from 'styled-components';
import {Button} from '../Widgets';
import Billing from '../Models/Billing';
import formatValidator from '../Utils/formatValidator';
import {ErrBilling, errorHandler} from '../errors';
import {message, Col, Row} from 'antd';
import {Link} from 'gatsby';
import {date} from '../Utils';

const {isNotEmpty} = formatValidator;

const LABELS = Billing.labels;

export default function BillingForm({record: _record, onUpdate}) {
  const {
    renderInput,
    renderText,
    renderCheckbox,
    renderDateTime,
    renderTextArea,
    renderCustom,
    record,
  } = useForm({record: _record});
  const app = useContext(Context);
  const edit = !!record.id;
  const isCustomer = !!record.customer;

  const valid = () => {
    // if (!isNotEmpty(record.name)) {
    //   throw new ErrBilling('廠商名稱不能為空');
    // }
  };

  const submit = async () => {
    const action = edit ? '編輯' : '新增';
    app.actions.setModalLoading(true);
    try {
      valid(record);

      const params = {
        query: edit ? {id: record.id} : {},
        data: record,
      };
      if (edit) {
        await app.actions.editBilling(params);
      } else {
        await app.actions.addBilling(params);
      }

      onUpdate();
      app.actions.setModal();

      message.success(action + '成功');
    } catch (ex) {
      errorHandler(ex, action + '錯誤');
    }
    app.actions.setModalLoading(false);
  };

  return (
    <Wrapper>
      <h3>{isCustomer ? '客戶' : '廠商'}帳務資訊</h3>
      <div className="content">
        {isCustomer ? (
          <Fragment>
            {renderCustom(LABELS['customer'], 'customer', {
              render: ({value}) => (
                <a href={`/customer?id=${value}`} target="_blank">
                  {record.customer_name || '-'}
                </a>
              ),
            })}
            {renderCustom(LABELS['quotation'], 'quotation', {
              render: ({value}) => (
                <a href={`/quotation?id=${value}`} target="_blank">
                  {record.quotation_display_id || '-'}
                </a>
              ),
            })}
            {renderText(LABELS['quotation_name'], 'quotation_name')}
          </Fragment>
        ) : (
          <Fragment>
            {renderCustom(LABELS['supplier'], 'supplier', {
              render: ({value}) => (
                <a href={`/supplier?id=${value}`} target="_blank">
                  {record.supplier_name || '-'}
                </a>
              ),
            })}
            {renderCustom(LABELS['dispatch'], 'dispatch', {
              render: ({value}) => (
                <a href={`/dispatch?id=${value}`} target="_blank">
                  {record.dispatch_display_id || '-'}
                </a>
              ),
            })}
            {renderText(LABELS['quotation_name'], 'quotation_name')}
          </Fragment>
        )}
        {renderText(LABELS['pay_period'], 'pay_period')}
        {renderCustom(LABELS['deadline'], 'deadline', {
          render: ({value}) => date.format(value),
        })}
        {renderText(LABELS['price'], 'price')}
        {renderTextArea(LABELS['note'], 'note')}
        {renderCustom(LABELS['complete'], 'complete', {
          render: ({value}) => (value ? '✔' : '✘'),
        })}
        {renderCustom(LABELS['void'], 'void', {
          render: ({value}) => (value ? '✔' : '✘'),
        })}
        {/* <Row>
          <Col span={12}>
            {renderCustom(LABELS['read'], 'read', {
              render: ({value}) => (value ? '✔' : '✘'),
            })}
          </Col>
          <Col span={12}>
            
          </Col>
        </Row> */}
      </div>
      <div className="footer">
        <Button onClick={submit}>確認</Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > h3 {
    padding: 20px 30px;
  }

  & > .content {
    flex: 1;
    padding: 30px;
  }

  & > .footer {
    border-top: solid 1px #ddd;
    padding: 20px 30px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
